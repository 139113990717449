var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12", attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v(
          " Create Economic Limit of " + _vm._s(_vm.year.getFullYear()) + " "
        ),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "pga-card-field-group",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Offer Limit",
                              "key-error": "OfferLimit",
                              errors: _vm.Errors,
                            },
                            model: {
                              value: _vm.offerLimit,
                              callback: function ($$v) {
                                _vm.offerLimit = $$v
                              },
                              expression: "offerLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "pga-card-field-group",
                { staticClass: "mb-3", attrs: { label: "Purchase Limits" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Purchase Inner Limit",
                              "key-error": "PurchaseInnerLimit",
                              errors: _vm.Errors,
                            },
                            model: {
                              value: _vm.purchaseInnerLimit,
                              callback: function ($$v) {
                                _vm.purchaseInnerLimit = $$v
                              },
                              expression: "purchaseInnerLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Purchase Annual Limit",
                              "key-error": "PurchaseAnnualLimit",
                              errors: _vm.Errors,
                            },
                            model: {
                              value: _vm.purchaseAnnualLimit,
                              callback: function ($$v) {
                                _vm.purchaseAnnualLimit = $$v
                              },
                              expression: "purchaseAnnualLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Purchase Outer Limit",
                              "key-error": "PurchaseOuterLimit",
                              errors: _vm.Errors,
                            },
                            model: {
                              value: _vm.purchaseOuterLimit,
                              callback: function ($$v) {
                                _vm.purchaseOuterLimit = $$v
                              },
                              expression: "purchaseOuterLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "pga-card-field-group",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2" } },
                        [
                          _c("pga-number-field", {
                            attrs: {
                              label: "MOL Min",
                              "key-error": "MOLMin",
                              suffix: "%",
                              decimal: "",
                              step: 0.1,
                              errors: _vm.Errors,
                            },
                            model: {
                              value: _vm.MOLMin,
                              callback: function ($$v) {
                                _vm.MOLMin = $$v
                              },
                              expression: "MOLMin",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Max Budget Inner Projects",
                              "key-error": "MaxBudgetInnerProjects",
                              errors: _vm.Errors,
                            },
                            model: {
                              value: _vm.maxBudgetInnerProjects,
                              callback: function ($$v) {
                                _vm.maxBudgetInnerProjects = $$v
                              },
                              expression: "maxBudgetInnerProjects",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }