var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditEconomicLimit" } },
            [
              _c("pga-edit-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canEdit,
                    expression: "canEdit",
                  },
                ],
                on: { click: _vm.Edit },
              }),
            ],
            1
          ),
          _vm.isApprovable
            ? _c(
                "pga-authorized-content",
                {
                  attrs: {
                    permissions: [
                      "ApproveEconomicLimitDG",
                      "ApproveEconomicLimitDHRG",
                    ],
                  },
                },
                [
                  _c("pga-approve-button", {
                    attrs: { text: "Approve" },
                    on: { click: _vm.approve },
                  }),
                  _c("pga-request-changes-button", {
                    on: { click: _vm.requestChanges },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        {
          attrs: {
            "is-loaded": _vm.EconomicLimitDetails.EconomicLimitDetailsView,
          },
        },
        [
          _c("pga-economic-limit-details", {
            model: {
              value: _vm.EconomicLimitDetails.EconomicLimitDetailsView,
              callback: function ($$v) {
                _vm.$set(
                  _vm.EconomicLimitDetails,
                  "EconomicLimitDetailsView",
                  $$v
                )
              },
              expression: "EconomicLimitDetails.EconomicLimitDetailsView",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }