var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "ReadEconomicLimit" } },
            [
              _c("pga-create-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canCreate,
                    expression: "canCreate",
                  },
                ],
                on: {
                  click: function ($event) {
                    return _vm.Navigate("/economiclimits/create")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { cols: "12" } },
        [_c("pga-economic-limits-table")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }