var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Economic Limits",
      headers: _vm.headers,
      data: _vm.EconomicLimits,
      loading: _vm.isLoading,
      "sort-by": "Year",
      "sort-desc": "",
      "col-props": _vm.columnProps,
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "column-Year",
        fn: function (props) {
          return [_vm._v(" " + _vm._s(props.row.item.Year.substr(0, 4)) + " ")]
        },
      },
      {
        key: "column-ApproveDHRG",
        fn: function (props) {
          return [
            _c("pga-switch", {
              attrs: { disabled: "" },
              model: {
                value: props.row.item.ApproveDHRG,
                callback: function ($$v) {
                  _vm.$set(props.row.item, "ApproveDHRG", $$v)
                },
                expression: "props.row.item.ApproveDHRG",
              },
            }),
          ]
        },
      },
      {
        key: "column-ApproveDG",
        fn: function (props) {
          return [
            _c("pga-switch", {
              attrs: { disabled: "" },
              model: {
                value: props.row.item.ApproveDG,
                callback: function ($$v) {
                  _vm.$set(props.row.item, "ApproveDG", $$v)
                },
                expression: "props.row.item.ApproveDG",
              },
            }),
          ]
        },
      },
      {
        key: "column-OfferLimit",
        fn: function (props) {
          return [
            _vm._v(
              " " + _vm._s(_vm.FormatMoney(props.row.item.OfferLimit)) + " "
            ),
          ]
        },
      },
      {
        key: "column-PurchaseInnerLimit",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.FormatMoney(props.row.item.PurchaseInnerLimit)) +
                " "
            ),
          ]
        },
      },
      {
        key: "column-PurchaseAnnualLimit",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.FormatMoney(props.row.item.PurchaseAnnualLimit)) +
                " "
            ),
          ]
        },
      },
      {
        key: "column-PurchaseOuterLimit",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.FormatMoney(props.row.item.PurchaseOuterLimit)) +
                " "
            ),
          ]
        },
      },
      {
        key: "column-MOLMin",
        fn: function (props) {
          return [_vm._v(" " + _vm._s(props.row.item.MOLMin) + " % ")]
        },
      },
      {
        key: "column-MaxBudgetInnerProjects",
        fn: function (props) {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.FormatMoney(props.row.item.MaxBudgetInnerProjects)) +
                " "
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }