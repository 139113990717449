var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditEconomicLimit" } },
            [_c("pga-save-button", { on: { click: _vm.Create } })],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        {
          attrs: {
            "is-loaded": _vm.EconomicLimitCreate.CreateEconomicLimitCommand,
          },
        },
        [
          _c("pga-economic-limit-create", {
            model: {
              value: _vm.EconomicLimitCreate.CreateEconomicLimitCommand,
              callback: function ($$v) {
                _vm.$set(
                  _vm.EconomicLimitCreate,
                  "CreateEconomicLimitCommand",
                  $$v
                )
              },
              expression: "EconomicLimitCreate.CreateEconomicLimitCommand",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }