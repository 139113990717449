var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12", attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v(
          " Economic Limit of " + _vm._s(_vm.year.getFullYear()) + " - Details "
        ),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "pga-card-field-group",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: { label: "Offer Limit", readonly: "" },
                            model: {
                              value: _vm.offerLimit,
                              callback: function ($$v) {
                                _vm.offerLimit = $$v
                              },
                              expression: "offerLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "pga-card-field-group",
                { staticClass: "mb-3", attrs: { label: "Purchase Limits" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Purchase Inner Limit",
                              readonly: "",
                            },
                            model: {
                              value: _vm.purchaseInnerLimit,
                              callback: function ($$v) {
                                _vm.purchaseInnerLimit = $$v
                              },
                              expression: "purchaseInnerLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Purchase Annual Limit",
                              readonly: "",
                            },
                            model: {
                              value: _vm.purchaseAnnualLimit,
                              callback: function ($$v) {
                                _vm.purchaseAnnualLimit = $$v
                              },
                              expression: "purchaseAnnualLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Purchase Outer Limit",
                              readonly: "",
                            },
                            model: {
                              value: _vm.purchaseOuterLimit,
                              callback: function ($$v) {
                                _vm.purchaseOuterLimit = $$v
                              },
                              expression: "purchaseOuterLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "pga-card-field-group",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2" } },
                        [
                          _c("pga-number-field", {
                            attrs: {
                              label: "MOL Min",
                              suffix: "%",
                              readonly: "",
                            },
                            model: {
                              value: _vm.MOLMin,
                              callback: function ($$v) {
                                _vm.MOLMin = $$v
                              },
                              expression: "MOLMin",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pga-currency-field", {
                            attrs: {
                              label: "Max Budget Inner Projects",
                              readonly: "",
                            },
                            model: {
                              value: _vm.maxBudgetInnerProjects,
                              callback: function ($$v) {
                                _vm.maxBudgetInnerProjects = $$v
                              },
                              expression: "maxBudgetInnerProjects",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "pga-card-field-group",
                { staticClass: "mb-3", attrs: { label: "Approvals Status" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c("pga-switch", {
                                attrs: {
                                  title: " DHRG Approval",
                                  disabled: "",
                                  label: _vm.approveDHRG
                                    ? "Approved"
                                    : "Not Approved",
                                },
                                model: {
                                  value: _vm.approveDHRG,
                                  callback: function ($$v) {
                                    _vm.approveDHRG = $$v
                                  },
                                  expression: "approveDHRG",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c("pga-switch", {
                                attrs: {
                                  title: "DG Approval",
                                  disabled: "",
                                  label: _vm.approveDG
                                    ? "Approved"
                                    : "Not Approved",
                                },
                                model: {
                                  value: _vm.approveDG,
                                  callback: function ($$v) {
                                    _vm.approveDG = $$v
                                  },
                                  expression: "approveDG",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }